// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-art-tsx": () => import("./../../../src/pages/art.tsx" /* webpackChunkName: "component---src-pages-art-tsx" */),
  "component---src-pages-bio-tsx": () => import("./../../../src/pages/bio.tsx" /* webpackChunkName: "component---src-pages-bio-tsx" */),
  "component---src-pages-coding-tsx": () => import("./../../../src/pages/coding.tsx" /* webpackChunkName: "component---src-pages-coding-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-design-tsx": () => import("./../../../src/pages/design.tsx" /* webpackChunkName: "component---src-pages-design-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-stickyindex-tsx": () => import("./../../../src/pages/stickyindex.tsx" /* webpackChunkName: "component---src-pages-stickyindex-tsx" */),
  "component---src-templates-art-template-tsx": () => import("./../../../src/templates/art-template.tsx" /* webpackChunkName: "component---src-templates-art-template-tsx" */),
  "component---src-templates-coding-template-tsx": () => import("./../../../src/templates/coding-template.tsx" /* webpackChunkName: "component---src-templates-coding-template-tsx" */),
  "component---src-templates-design-template-tsx": () => import("./../../../src/templates/design-template.tsx" /* webpackChunkName: "component---src-templates-design-template-tsx" */)
}

